import { ChangeEvent, FormEvent, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import BookTableItem from "../../common/components/book_table_item/BookTableItem";
import { Book } from "../../common/types/Book";
import { clearNotify, setNotify } from "../../features/notify.feature";
import {
  clearBookForm,
  deleteSelectedBook,
  fetchBooks,
  setAllSelectBooks,
  setBookForm,
  submitBook,
} from "../../features/panel.feature";
import styles from "./Panel.module.scss";

function Panel() {
  const { books, bookForm, selectedBooks } = useAppSelector(
    (state: RootState) => state.panel
  );
  const { isLoading } = useAppSelector((state: RootState) => state.loader);
  const dispatch = useAppDispatch();
  useEffect(() => {
    books.length === 0 && dispatch(fetchBooks());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(setBookForm({ name, value }));
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(clearNotify());
    if (!bookForm.title) {
      dispatch(setNotify({ warning: `Title is required` }));
      return;
    }
    if (!bookForm.author) {
      dispatch(setNotify({ warning: `Author is required` }));
      return;
    }

    dispatch(submitBook(bookForm));
  };

  const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setAllSelectBooks(e.target.checked));
  };

  const handleDeleteSeleceted = () => {
    if (selectedBooks.length === 0) {
      dispatch(setNotify({ warning: "No books were seleced" }));
      return;
    }
    dispatch(deleteSelectedBook(selectedBooks));
  };

  return (
    <div className={styles.panel}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Title"
          name="title"
          value={bookForm.title}
          disabled={isLoading}
          onChange={handleChange}
        />
        <input
          type="text"
          placeholder="Author"
          name="author"
          value={bookForm.author}
          disabled={isLoading}
          onChange={handleChange}
        />
        <div className={styles.form__actions}>
          <button
            type="submit"
            className={styles.form__submit + " btn__contaiend"}
            disabled={isLoading}
          >
            Add
          </button>
          <button
            type="button"
            className={styles.form__clear + " btn__contaiend"}
            disabled={isLoading}
            onClick={() => {
              dispatch(clearBookForm());
            }}
          >
            clear
          </button>
        </div>
      </form>
      <div className={styles.table__container}>
        <div className={styles.table__actions}>
          <button
            className={styles.table__delete__selected + " btn__contaiend"}
            disabled={isLoading || selectedBooks.length === 0}
            onClick={handleDeleteSeleceted}
          >
            Delete selected
          </button>
        </div>
        <div className={styles.table}>
          <table>
            <thead>
              <tr>
                <th>Id</th>
                <th>Title</th>
                <th>Author</th>
                <th></th>
                <th>
                  <input
                    type="checkbox"
                    checked={
                      selectedBooks.length > 0 &&
                      selectedBooks.length === books.length
                    }
                    disabled={isLoading}
                    onChange={handleSelectAll}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {books.length > 0 ? (
                books.map((book: Book, index: number) => (
                  <BookTableItem key={book._id} book={book} index={index} />
                ))
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    rowSpan={5}
                    style={{ paddingBlock: "64px", color: "red" }}
                  >
                    <h2>No Data</h2>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Panel;
