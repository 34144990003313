import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import homeFeature from "../features/home.feature";
import loaderFeature from "../features/loader.feature";
import notifyFeature from "../features/notify.feature";
import panelFeature from "../features/panel.feature";

export const store = configureStore({
  reducer: {
    home: homeFeature,
    panel: panelFeature,
    loader: loaderFeature,
    notify: notifyFeature,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
