import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  error: undefined as string | undefined,
  success: undefined as string | undefined,
  isNotify: false,
  isNotifyActive: true,
};

const loaderSlice = createSlice({
  name: "loader",
  initialState: initialState,
  reducers: {
    setLoader: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setLoader } = loaderSlice.actions;
export default loaderSlice.reducer;
