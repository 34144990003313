import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  status: "idle",
};

const homeSlice = createSlice({
  name: "home",
  initialState: initialState,
  reducers: {},
});

export default homeSlice.reducer;
