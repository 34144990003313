import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import styles from "./Home.module.scss";
import book_1 from "../../assets/imgs/book_1.png";
import { fetchBooks } from "../../features/panel.feature";
import { Link } from "react-router-dom";
import { Book } from "../../common/types/Book";

function Home() {
  const { books } = useAppSelector((state: RootState) => state.panel);
  const dispatch = useAppDispatch();

  useEffect(() => {
    books.length === 0 && dispatch(fetchBooks());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.home}>
      {books.length > 0 ? (
        <div className={styles.container}>
          <div className={styles.books__grid}>
            {books?.map((item: Book) => (
              <div className={styles.card} key={item._id}>
                <div className={styles.card__img}>
                  <img src={book_1} alt={item.title} />
                </div>
                <h3>{item.title}</h3>
                <p>{item.author}</p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <p className={styles.no__data}>
          No Books yet,{" "}
          <strong>
            <Link to="/" className="anchor__colored">
              Add Some!
            </Link>
          </strong>
        </p>
      )}
    </div>
  );
}

export default Home;
