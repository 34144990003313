import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import httpClient from "../common/services/HttpClient";
import { Book } from "../common/types/Book";
import { setNotify } from "./notify.feature";

const initialState = {
  status: "idle",
  books: [] as Book[],
  bookForm: {
    title: "",
    author: "",
  },
  editMode: "",
  bookEditForm: {
    _id: "",
    title: "",
    author: "",
  } as Book,
  selectedBooks: [] as string[],
};

export const fetchBooks = createAsyncThunk(
  "panel/fetchBooks",
  async (_, { dispatch }) => {
    try {
      const { data } = await httpClient.get("api/book");
      return await data;
    } catch (error: any) {
      dispatch(setNotify({ error: error.response.data.message }));
      throw await error;
    }
  }
);

export const submitBook = createAsyncThunk(
  "panel/submitBook",
  async ({ title, author }: any, { dispatch }) => {
    try {
      const { data } = await httpClient.post("api/book", { title, author });
      dispatch(fetchBooks());
      dispatch(setNotify({ success: data }));
      dispatch(clearBookForm());
    } catch (error: any) {
      dispatch(setNotify({ error: error.response.data.message }));
      throw await error;
    }
  }
);

export const editBook = createAsyncThunk(
  "panel/editBook",
  async (book: Book, { dispatch }) => {
    try {
      const { data } = await httpClient.patch(`api/book/${book._id}`, book);
      dispatch(fetchBooks());
      dispatch(setNotify({ success: data }));
      dispatch(cancelBookEditForm());
    } catch (error: any) {
      dispatch(setNotify({ error: error.response.data.message }));
      throw await error;
    }
  }
);

export const deleteBook = createAsyncThunk(
  "panel/deleteBook",
  async (bookId: string, { dispatch }) => {
    try {
      const { data } = await httpClient.delete(`api/book/${bookId}`);
      dispatch(fetchBooks());
      dispatch(setNotify({ success: data }));
      dispatch(setSelectedBooks({ id: bookId }));
    } catch (error: any) {
      dispatch(setNotify({ error: error.response.data.message }));
      throw await error;
    }
  }
);

export const deleteSelectedBook = createAsyncThunk(
  "panel/deleteSelectedBook",
  async (bookIdList: string[], { dispatch }) => {
    try {
      const { data } = await httpClient.delete(
        `api/book/list/${bookIdList.join(",")}`
      );
      dispatch(fetchBooks());
      dispatch(setNotify({ success: data }));
      bookIdList.forEach((id) => {
        dispatch(setSelectedBooks({ id }));
      });
    } catch (error: any) {
      dispatch(setNotify({ error: error.response.data.message }));
      throw await error;
    }
  }
);

const panelSlice = createSlice({
  name: "panel",
  initialState: initialState,
  reducers: {
    setBookForm: (state, action) => {
      state.bookForm = {
        ...state.bookForm,
        [action.payload.name]: action.payload.value,
      };
    },
    clearBookForm: (state) => {
      state.bookForm = Object.fromEntries(
        Object.entries(state.bookForm)?.map((item: any) => {
          item[1] = "";
          return item;
        })
      ) as typeof state.bookForm;
    },
    setEditMode: (state, action) => {
      state.bookEditForm = action.payload;
      state.editMode = action.payload._id;
    },
    setBookEditForm: (state, action) => {
      state.bookEditForm = {
        ...state.bookEditForm,
        [action.payload.name]: action.payload.value,
      };
    },
    cancelBookEditForm: (state) => {
      state.bookEditForm = Object.fromEntries(
        Object.entries(state.bookEditForm)?.map((item: any) => {
          item[1] = "";
          return item;
        })
      ) as Book;
      state.editMode = "";
    },
    setSelectedBooks: (state, action) => {
      if (action.payload.checked) {
        state.selectedBooks.push(action.payload.id);
      } else {
        state.selectedBooks = state.selectedBooks.filter(
          (id) => id !== action.payload.id
        );
      }
    },
    setAllSelectBooks: (state, action) => {
      if (action.payload) {
        state.selectedBooks = state.books?.map(
          (book: Book) => book._id
        ) as string[];
      } else {
        state.selectedBooks = [];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBooks.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBooks.fulfilled, (state, action) => {
        state.status = "idle";
        state.books = action.payload;
      })
      .addCase(fetchBooks.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(submitBook.pending, (state) => {
        state.status = "loading";
      })
      .addCase(submitBook.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(editBook.pending, (state) => {
        state.status = "loading";
      })
      .addCase(editBook.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(deleteBook.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteBook.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(deleteSelectedBook.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteSelectedBook.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {
  setBookForm,
  clearBookForm,
  setEditMode,
  setBookEditForm,
  cancelBookEditForm,
  setSelectedBooks,
  setAllSelectBooks,
} = panelSlice.actions;
export default panelSlice.reducer;
