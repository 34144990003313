import { useEffect, useRef } from "react";
import { Icon } from '@iconify/react';
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import {
  clearNotify,
  setNotifyActive,
  setNotifyMounted,
} from "../../../features/notify.feature";

import styles from "./Notify.module.scss";

function Notify() {
  const { error, success, warning, isNotifyActive } = useAppSelector(
    (state: RootState) => state.notify
  );
  const dispatch = useAppDispatch();
  const refTimeout = useRef<NodeJS.Timeout>(null!);
  const refTimeoutActive = useRef<NodeJS.Timeout>(null!);

  useEffect(() => {
    refTimeout.current = setTimeout(() => {
      dispatch(setNotifyMounted(false));
    }, 4000);
    refTimeoutActive.current = setTimeout(() => {
      dispatch(setNotifyActive(false));
    }, 1500);
    return () => {
      clearTimeout(refTimeout.current);
      clearTimeout(refTimeoutActive.current);
      dispatch(clearNotify());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={
        styles.notify +
        (success ? " " + styles.success : "") +
        (warning ? " " + styles.warning : "") +
        (error ? " " + styles.error : "") +
        (isNotifyActive ? " " + styles.active : "")
      }
      onMouseEnter={() => {
        clearTimeout(refTimeout.current);
        clearTimeout(refTimeoutActive.current);
        dispatch(setNotifyActive(true));
      }}
      onMouseLeave={() => {
        dispatch(setNotifyActive(false));
        refTimeout.current = setTimeout(() => {
          dispatch(setNotifyMounted(false));
        }, 2500);
      }}
    >
      <p>
        {success && success}
        {warning && warning}
        {error && error}
      </p>
      <button
        onClick={() => {
          dispatch(setNotifyMounted(false));
        }}
      >
        <Icon icon="ep:circle-close-filled" />
      </button>
    </div>
  );
}

export default Notify;
