import { ChangeEvent } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Icon } from '@iconify/react';
import { RootState } from "../../../app/store";
import {
  cancelBookEditForm,
  deleteBook,
  setEditMode,
  setBookEditForm,
  editBook,
  setSelectedBooks,
} from "../../../features/panel.feature";
import { Book } from "../../types/Book";
import styles from "./BookTableItem.module.scss";

function BookTableItem({ book, index }: { book: Book; index: number }) {
  const dispatch = useAppDispatch();
  const { selectedBooks } = useAppSelector((state: RootState) => state.panel);
  const { isLoading } = useAppSelector((state: RootState) => state.loader);
  const { bookEditForm, editMode } = useAppSelector(
    (state: RootState) => state.panel
  );
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(setBookEditForm({ name, value }));
  };
  const handleEdit = () => {
    dispatch(setEditMode(book));
  };
  const handleCancelEdit = () => {
    dispatch(cancelBookEditForm());
  };
  const handleDelete = () => {
    dispatch(deleteBook(book._id));
  };
  const handleSave = () => {
    dispatch(editBook(bookEditForm));
  };
  const handleSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    dispatch(setSelectedBooks({ checked, id: book._id }));
  };
  return (
    <tr className={styles.row}>
      <td>{index + 1}</td>
      {editMode === book._id ? (
        <>
          <td>
            <input
              type="text"
              name="title"
              value={bookEditForm.title}
              disabled={isLoading}
              onChange={handleChange}
            />
          </td>
          <td>
            <input
              type="text"
              name="author"
              value={bookEditForm.author}
              disabled={isLoading}
              onChange={handleChange}
            />
          </td>
          <td className={styles.actions}>
            <button
              className={styles.success}
              onClick={handleSave}
              disabled={isLoading}
            >
              <Icon icon="material-symbols:save" />
            </button>
            <button
              className={styles.delete}
              onClick={handleCancelEdit}
              disabled={isLoading}
            >
              <Icon icon="material-symbols:cancel-outline-rounded" />
            </button>
          </td>
        </>
      ) : (
        <>
          <td>{book.title}</td>
          <td>{book.author}</td>
          <td className={styles.actions}>
            <button
              className={styles.edit}
              aria-label="edit book"
              disabled={isLoading}
              onClick={handleEdit}
            >
              <Icon icon="material-symbols:edit" />
            </button>
            <button
              className={styles.delete}
              aria-label="delete book"
              disabled={isLoading}
              onClick={handleDelete}
            >
              <Icon icon="mdi:trash-can-outline" />
            </button>
          </td>
        </>
      )}
      <td>
        <input
          type="checkbox"
          checked={selectedBooks?.includes(book._id)}
          disabled={isLoading}
          onChange={handleSelect}
        />
      </td>
    </tr>
  );
}

export default BookTableItem;
