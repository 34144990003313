import styles from "./Footer.module.scss";

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.container + " container"}>
        <span>2022 © Copyright. All Rights Reserved</span>
        <span>
          Designed by{" "}
          <a
            href="https://ahmed-ouda.vercel.app"
            target="_blank"
            rel="noreferrer"
            className="anchor__colored"
          >
            Ahmed Ouda
          </a>
        </span>
      </div>
    </footer>
  );
}

export default Footer;
