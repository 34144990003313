import { Route, Routes } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { RootState } from "./app/store";
import Header from "./common/components/header/Header";
import Notify from "./common/components/notify/Notify";
import Loader from "./common/components/loader/Loader";
import Home from "./pages/home/Home";
import Panel from "./pages/panel/Panel";
import { useEffect } from "react";
import { setLoader } from "./features/loader.feature";
import Footer from "./common/components/footer/Footer";

function App() {
  const { isLoading } = useAppSelector((state: RootState) => state.loader);
  const { isNotify } = useAppSelector((state: RootState) => state.notify);
  const homeStatus = useAppSelector((state: RootState) => state.home.status);
  const panelStatus = useAppSelector((state: RootState) => state.panel.status);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setLoader(homeStatus === "loading" || panelStatus === "loading"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeStatus, panelStatus]);

  return (
    <>
      <Header />
      <Routes>
        <Route index element={<Panel />} />
        <Route path="/browse" element={<Home />} />
        <Route
          path="*"
          element={<h1 style={{ color: "red" }}>404 Page Not Found</h1>}
        />
      </Routes>
      <Footer />
      {isLoading && <Loader />}
      {isNotify && <Notify />}
    </>
  );
}

export default App;
