import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isNotify: false,
  isNotifyActive: true,
  error: undefined as string | undefined,
  success: undefined as string | undefined,
  warning: undefined as string | undefined,
};

const notifySlice = createSlice({
  name: "notify",
  initialState: initialState,
  reducers: {
    setNotify: (state, action) => {
      state.isNotify = true;
      state.isNotifyActive = true;
      state.error = action.payload.error;
      state.success = action.payload.success;
      state.warning = action.payload.warning;
    },
    clearNotify: (state) => {
      state.isNotify = false;
      state.isNotifyActive = true;
      state.error = undefined;
      state.success = undefined;
      state.warning = undefined;
    },
    setNotifyActive: (state, action) => {
      state.isNotifyActive = action.payload;
    },
    setNotifyMounted: (state, action) => {
      state.isNotify = action.payload;
    },
  },
});

export const { setNotify, clearNotify, setNotifyActive, setNotifyMounted } =
  notifySlice.actions;
export default notifySlice.reducer;
